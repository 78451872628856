/* eslint-disable @typescript-eslint/no-unused-vars */

function initialize(trackingCode, options) {
  // Empty
}

function set(fieldsObject, trackerNames) {
  // Empty
}

function pageview(path, trackerNames, title) {
  // Empty
}

function timing(args, trackerNames) {
  // Empty
}

function event(args, trackerNames) {
  // Empty
}

function exception(fieldsObject, trackerNames) {
  // Empty
}

function outboundLink(args, hitCallback, trackerNames) {
  hitCallback()
}

// const ReactGA = {
//   initialize,
//   set,
//   pageview,
//   timing,
//   event,
//   exception,
//   outboundLink,
// }

// default ReactGA
module.exports = {
  initialize,
  set,
  pageview,
  timing,
  event,
  exception,
  outboundLink,
}
