import { useEffect } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { useAppDispatch } from 'state/hooks'

import { ApplicationModal, setOpenModal } from '../../state/application/reducer'

// Redirects to swap but only replace the pathname
export function RedirectPathToSwapOnly({ location }: RouteComponentProps) {
  let newPathname = location.pathname

  // Check if pathname ends with '/' or '/?'
  if (newPathname.endsWith('/')) {
    newPathname += 'swap'
  } else if (newPathname.endsWith('/?')) {
    // Replace '/?' with '/swap'
    newPathname = newPathname.replace(/\/\?$/, '/swap')
  } else {
    newPathname += '/swap'
  }

  return <Redirect to={{ ...location, pathname: newPathname }} />
}

// Redirects from the /swap/:outputCurrency path to the /swap?outputCurrency=:outputCurrency format
export function RedirectToSwap(props: RouteComponentProps<{ outputCurrency: string }>) {
  const {
    location: { search, pathname },
    match: {
      params: { outputCurrency },
    },
  } = props

  // Ensure there's no double slash when concatenating paths
  const baseSwapPath = pathname.endsWith('/') ? 'swap' : '/swap'

  // Construct the relative path
  const relativePath = `${baseSwapPath}${
    search && search.length > 1 ? `${search}&` : '?'
  }outputCurrency=${outputCurrency}`

  return (
    <Redirect
      to={{
        pathname: pathname + relativePath,
        search: '',
      }}
    />
  )
}

export function OpenClaimAddressModalAndRedirectToSwap(props: RouteComponentProps) {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setOpenModal(ApplicationModal.ADDRESS_CLAIM))
  }, [dispatch])
  return <RedirectPathToSwapOnly {...props} />
}
