import { getStatic } from '@ethersproject/properties'
import { InfuraProvider, Network } from '@ethersproject/providers'
import { ConnectionInfo } from '@ethersproject/web'
import { ChainId, ID_TO_NETWORK_NAME } from '@uniswap/smart-order-router'
import { INFURA_NETWORK_URLS } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'

const INFURA_URL_TO_CHAIN_ID = {
  [INFURA_NETWORK_URLS[SupportedChainId.MAINNET]]: SupportedChainId.MAINNET,
  [INFURA_NETWORK_URLS[SupportedChainId.RINKEBY]]: SupportedChainId.RINKEBY,
  [INFURA_NETWORK_URLS[SupportedChainId.ROPSTEN]]: SupportedChainId.ROPSTEN,
  [INFURA_NETWORK_URLS[SupportedChainId.GOERLI]]: SupportedChainId.GOERLI,
  [INFURA_NETWORK_URLS[SupportedChainId.KOVAN]]: SupportedChainId.KOVAN,
  [INFURA_NETWORK_URLS[SupportedChainId.OPTIMISM]]: SupportedChainId.OPTIMISM,
  [INFURA_NETWORK_URLS[SupportedChainId.OPTIMISTIC_KOVAN]]: SupportedChainId.OPTIMISTIC_KOVAN,
  [INFURA_NETWORK_URLS[SupportedChainId.ARBITRUM_ONE]]: SupportedChainId.ARBITRUM_ONE,
  [INFURA_NETWORK_URLS[SupportedChainId.ARBITRUM_RINKEBY]]: SupportedChainId.ARBITRUM_RINKEBY,
  [INFURA_NETWORK_URLS[SupportedChainId.POLYGON]]: SupportedChainId.POLYGON,
  [INFURA_NETWORK_URLS[SupportedChainId.POLYGON_MUMBAI]]: SupportedChainId.POLYGON_MUMBAI,
}

const INFURA_PREFIX = 'https://mainnet.infura.io/v3/'
const INFURA_API_KEY = INFURA_NETWORK_URLS[SupportedChainId.MAINNET].replace(INFURA_PREFIX, '')

const MAINNET_NETWORK_NAME = 'homestead'

function networkName(chainId: SupportedChainId): string {
  return chainId === SupportedChainId.MAINNET ? MAINNET_NETWORK_NAME : ID_TO_NETWORK_NAME(chainId)
}

class CustomInfuraProvider extends InfuraProvider {
  constructor(url: string) {
    const chainId = INFURA_URL_TO_CHAIN_ID[url]
    const name = networkName(chainId)

    const network: Network = { name, chainId }

    super(network, INFURA_API_KEY)
  }

  // This terrible hack is needed to avoid updating all dependencies just to
  // get a newer @ethersproject/providers with a InfuraProvider that includes
  // all the necessary network endpoints of INFURA_NETWORK_URLS

  static getUrl(network: Network, apiKey: any): ConnectionInfo {
    if (network && network.chainId) {
      const mainnet = { name: MAINNET_NETWORK_NAME, chainId: 1 }

      const connection = getStatic<typeof this.getUrl>(InfuraProvider, 'getUrl')(mainnet, apiKey)

      connection.url = INFURA_NETWORK_URLS[network.chainId as ChainId]
      return connection
    }
    return getStatic<typeof this.getUrl>(InfuraProvider, 'getUrl')(network, apiKey)
  }
}

export { CustomInfuraProvider as JsonRpcProvider }
