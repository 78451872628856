let _isEmbeddedHexCom: boolean

export const isEmbedded = () => window !== window.top

function topDomain(): string {
  const { ancestorOrigins } = window.location
  const origin = (ancestorOrigins && ancestorOrigins[0]) || document.referrer
  return origin ? new URL(origin).hostname : ''
}

function isTopDomainHexCom(): boolean {
  const domain = topDomain()
  return domain === 'hex.com' || domain.endsWith('.hex.com')
}

export function isEmbeddedHexCom(): boolean {
  if (_isEmbeddedHexCom === undefined) {
    _isEmbeddedHexCom = isEmbedded() && isTopDomainHexCom()
  }
  return _isEmbeddedHexCom
}
