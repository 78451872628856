import { Trans } from '@lingui/macro'
import Card, { DarkGreyCard } from 'components/Card'
import { AutoRow, RowBetween } from 'components/Row'
import { useEffect, useRef, useState } from 'react'
import { ArrowLeft, ArrowRight, Info, X } from 'react-feather'
import ReactGA from 'react-ga'
import styled from 'styled-components/macro'
import { ExternalLink, StyledInternalLink, ThemedText } from 'theme'
import { isMobile } from 'utils/userAgent'

import { useModalOpen, useTogglePrivacyPolicy } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { AutoColumn } from '../Column'
import Modal from '../Modal'

const Wrapper = styled.div`
  max-height: 70vh;
  overflow: auto;
  padding: 0 1rem;
`

const StyledExternalCard = styled(Card)`
  background-color: ${({ theme }) => theme.primary5};
  padding: 0.5rem;
  width: 100%;

  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.primary4};
  }
`

const StyledDisclaimerCard = styled(Card)`
  background-color: ${({ theme }) => theme.primary5};
  padding: 0.5rem;
  width: 100%;
  font-size: 12px;
`

const BackArrow = styled(ArrowLeft)`
  cursor: pointer;
  position: relative;
  top: 0.25rem;
  margin-right: 4px;
`

const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`

const EXTERNAL_APIS = [
  {
    name: 'Infura',
    description: <Trans>The app fetches on-chain data and constructs contract calls with an Infura API.</Trans>,
  },
  {
    name: 'The Graph',
    description: <Trans>The app fetches blockchain data from The Graph’s hosted service.</Trans>,
  },
]

export function PrivacyPolicyModal() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.PRIVACY_POLICY)
  const toggle = useTogglePrivacyPolicy()

  useEffect(() => {
    if (!open) return

    ReactGA.event({
      category: 'Modal',
      action: 'Show Legal',
    })
  }, [open])

  return (
    <Modal isOpen={open} onDismiss={() => toggle()}>
      <AutoColumn gap="12px" ref={node as any}>
        <RowBetween padding="1rem 1rem 0.5rem 1rem">
          <ThemedText.MediumHeader>
            <Trans>Legal & Privacy</Trans>
          </ThemedText.MediumHeader>
          <HoverText onClick={() => toggle()}>
            <X size={24} />
          </HoverText>
        </RowBetween>
        <PrivacyPolicy />
      </AutoColumn>
    </Modal>
  )
}

export function PrivacyPolicy() {
  const [disclaimer, setDisclaimer] = useState<boolean>(false)

  return (
    <Wrapper
      draggable="true"
      onTouchMove={(e) => {
        // prevent modal gesture handler from dismissing modal when content is scrolling
        if (isMobile) {
          e.stopPropagation()
        }
      }}
    >
      <AutoColumn gap="16px">
        {disclaimer ? (
          <>
            <ThemedText.SubHeader>
              <BackArrow size={18} onClick={() => setDisclaimer(false)} />
              <Trans>Disclaimer</Trans>
            </ThemedText.SubHeader>
            <AutoColumn gap="8px" style={{ width: '100%', marginBottom: '1rem' }}>
              <StyledDisclaimerCard>
                <p>
                  This is:{' '}
                  <ExternalLink href="https://github.com/Uniswap/uniswap-interface">
                    github.com/Uniswap/uniswap-interface
                  </ExternalLink>
                </p>

                <p>
                  Security audit:{' '}
                  <ExternalLink href="https://github.com/ConsenSys/Uniswap-audit-report-2018-12">
                    github.com/ConsenSys/Uniswap-audit-report-2018-12
                  </ExternalLink>
                </p>

                <p>
                  You take full responsibility for running this code. There are many other URLs you can run it from, and
                  you can upload it to your own URL as well.
                </p>

                <p>Other examples:</p>

                <p style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                  <ExternalLink href="https://ipfs.io/ipfs/QmXtJLUESCgsKiLjhC5wYXKKxu8kA1kgJWAMQEJ2c7vVVS/">
                    ipfs.io/ipfs/QmXtJLUESCgsKiLjhC5wYXKKxu8kA1kgJWAMQEJ2c7vVVS/
                  </ExternalLink>
                  <br />
                  <br />
                  <ExternalLink href="https://ipfs.io/ipfs/QmZsv4n23MezRymqaWKb3dTudw7bggyTV8msPcdtndKKRN/#swap/0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39">
                    ipfs.io/ipfs/QmZsv4n23MezRymqaWKb3dTudw7bggyTV8msPcdtndKKRN/
                  </ExternalLink>
                  <br />
                  <br />
                  <ExternalLink href="https://gateway.ipfs.io/ipfs/QmTUBnZXk2Yp8TY8N4NDE3fBGKkDohTDrg2p6kQYXj4Sne/#swap/0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39">
                    gateway.ipfs.io/ipfs/QmTUBnZXk2Yp8TY8N4NDE3fBGKkDohTDrg2p6kQYXj4Sne/
                  </ExternalLink>
                </p>
              </StyledDisclaimerCard>
            </AutoColumn>
          </>
        ) : (
          <>
            <AutoColumn gap="8px" style={{ width: '100%' }}>
              <StyledExternalCard>
                <StyledInternalLink onClick={() => setDisclaimer(true)} to={''}>
                  <RowBetween>
                    <AutoRow gap="4px">
                      <Info size={20} />
                      <ThemedText.Main fontSize={14} color={'primaryText1'}>
                        <Trans>Disclaimer</Trans>
                      </ThemedText.Main>
                    </AutoRow>
                    <ArrowRight size={18} />
                  </RowBetween>
                </StyledInternalLink>
              </StyledExternalCard>
            </AutoColumn>
            <ThemedText.Main fontSize={14}>
              <Trans>This app uses the following third-party APIs:</Trans>
            </ThemedText.Main>
            <AutoColumn gap="12px" style={{ marginBottom: '1rem' }}>
              {EXTERNAL_APIS.map(({ name, description }, i) => (
                <DarkGreyCard key={i}>
                  <AutoColumn gap="8px">
                    <AutoRow gap="4px">
                      <Info size={18} />
                      <ThemedText.Main fontSize={14} color={'text1'}>
                        {name}
                      </ThemedText.Main>
                    </AutoRow>
                    <ThemedText.Main fontSize={14}>{description}</ThemedText.Main>
                  </AutoColumn>
                </DarkGreyCard>
              ))}
            </AutoColumn>
          </>
        )}
      </AutoColumn>
    </Wrapper>
  )
}
