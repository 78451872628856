import { Token } from '@uniswap/sdk-core'
import { TokenInfo, TokenList } from '@uniswap/token-lists'
import { DEFAULT_ACTIVE_LIST_URLS } from 'constants/lists'
import { HEX } from 'constants/tokens'

export function mergeIntoTokenList(listUrl: string, tokenList: TokenList): boolean {
  if (!DEFAULT_ACTIVE_LIST_URLS.includes(listUrl)) {
    return false
  }
  return prependTokenList(tokenList, HEX)
}

function prependTokenList(tokenList: TokenList, token: Required<Token>): boolean {
  if (tokenList.tokens.some((ti) => tokenInfoIsToken(ti, token))) {
    return false
  }

  tokenList.tokens.unshift({
    chainId: token.chainId,
    address: token.address,
    name: token.name,
    decimals: token.decimals,
    symbol: token.symbol,
  })
  return true
}

function tokenInfoIsToken(ti: TokenInfo, t: Token): boolean {
  return ti.symbol === t.symbol && ti.address === t.address && ti.chainId === t.chainId
}
